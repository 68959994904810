import React from "react";
import { HStack, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text } from "@chakra-ui/react";

import { Nullable } from "foundation-ts/types";
import { $string } from "foundation-ts/commons";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

interface WaitingModalProps {
    isOpen: boolean;
    onClose: () => void;
    message?:Nullable<string>
} ;
  
export const WaitingModal = (props: WaitingModalProps) => {
    const { isOpen, onClose, message } = props ;
    return (
        <Modal onClose={onClose} size="xs" isOpen={isOpen} closeOnEsc={false} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                    <ModalBody>
                        <HStack>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor={$g1Color('spinner.bg')}
                                color={$g1Color('spinner.write')}
                                size='lg'
                            />
                            <Text>{$string(message)}</Text>
                        </HStack>
                    </ModalBody>
                </ModalContent>

        </Modal>
    ) ;
}
